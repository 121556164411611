
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { replaceComma } from "@/utils/verify";
import { Table } from "@/utils/decorator";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const finance = namespace("finance");
@Component({})
@Table("loadListData")
export default class TmallBillView extends Vue {
  @finance.Action getReceiptDetail;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  replaceComma = replaceComma;
  dayjs = dayjs;
  fissionPayment = [
    {
      paidMethodDesc: t("v210801.bank-transfer"),
      paidAmount: null
    }
  ];
  arrayProductCodes(row) {
    return row.productCodes.split(",");
  }
  get breadData() {
    return [
      { name: t("finance.receipt"), path: "/finance/collection-order" },
      { name: this.$t("finance.receipt-details") }
    ];
  }
  get receiptId() {
    return this.$route.params.id;
  }
  get type() {
    // 字典值 1：百姓车联 2：销售 9：其他
    return String(this.detailData.receiptType);
  }
  handleLinkServiceView(row, tenantCode) {
    let type = row.orderNo.slice(0, 2);
    let url = "";
    if (type === "CG") {
      url = "/purchase/purchase-view/";
    } else if (type === "TH") {
      url = "/purchase/purchase-refund-view/";
    } else {
      url = "/service/view/";
    }
    let { href } = this.$router.resolve({
      path: url + row.orderNo + "?tenantCode=" + tenantCode
    });
    window.open(href, "_blank");
  }
  orderList: any[] = [];
  fissionOrderList: any[] = [];
  detailData: any = {};
  created() {
    this.init();
  }
  init() {
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getReceiptDetail({
      receiptNo: this.receiptId,
      orderItems: this.mix_sortParams,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum
    }).then(data => {
      this.detailData = data.data;
      if (data.data.paidProof) {
        this.fissionPayment[0].paidAmount = data.data.paidProof.paidAmount;
      }
      this.orderList = data.data.orderList;
      this.fissionOrderList = data.data.fissionOrderSummaries || [];
      return data.data.orderTotalCount;
      // this.mix_total = data.data.orderTotalCount;
    });
  }
  handleLinkView(item) {
    const { href } = this.$router.resolve(`/marketing/card-view/${this.replaceComma(item)}`);
    window.open(href);
  }
  // ///
}
